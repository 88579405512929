import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import logo from '../../images/logo-white.svg';
import Toggle from '../Toggle/Toggle';

const WrappedHeader = styled.header`
  position: fixed;
  background: ${props => props.theme.colors.brand};
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: ${props => props.theme.spacing.s};

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding: ${props => props.theme.spacing.m};
  }
`;

const FlexHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: auto;
  height: 100%;
  max-height: 40px;

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    max-height: 40px;
  }
`;

export default function Header(props) {
  const title = 'Iquality 25 jaar logo';

  return (
    <WrappedHeader>
      <FlexHeader>
        <Link to="/">
          <Logo
            style={{ width: '200px', display: 'block' }}
            src={logo}
            alt={title}
          />
        </Link>
        <Toggle
          toggleActive={props.isMenuToggled}
          onClickAction={props.toggleMenu}
        />
      </FlexHeader>
    </WrappedHeader>
  );
}
