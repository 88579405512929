import loadable from '@loadable/component';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import { mapTypeNameToComponentName } from '../utils';
import Header from '../components/Header/Header';
import { graphql } from 'gatsby';

const AsyncComponent = loadable(props =>
  import(`../components/${props.name}/${props.name}`)
);

const NotFoundPage = props => {
  const { data } = props;
  const { contentfulPage } = data;
  const {
    title,
    content,
    template,
    description,
    navigation,
  } = contentfulPage;
  const components = content.map(item => {
    const { __typename, id } = item;
    return {
      name: mapTypeNameToComponentName(__typename),
      id,
      forwardedProps: item,
    };
  });
  return (
    <Layout template={template}>
      <SEO title={title} description={description} />
      <div id="navigation">
        <Header {...navigation} />
      </div>
      <div id="main">
        {components.map(component => (
          <AsyncComponent
            key={component.id}
            name={component.name}
            {...component.forwardedProps}
          />
        ))}
      </div>

    </Layout>
  );
};

export const query = graphql`
  query pageNotFoundQuery {
    contentfulPage(slug: { eq: "404" }) {
      ...PageFragment
    }
  }
`;

export default NotFoundPage;
