import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  margin-right: 0;
  padding: 0;
  position: relative;
  color: white;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  text-decoration: none;
  transition: all 300ms linear;

  &:hover,
  &:focus {

    [class^='Button__ButtonArrow'],
    [class^='Button__ButtonIcon'] {
      width: 35px;
      transition: all 300ms linear;
    }

    [class^='Button__ButtonLabel'] {

    }
  }
`;

const ButtonInner = styled.span`
  display: flex;
  overflow: hidden;
  padding-left: calc(.8rem + 1px);
  justify-content: flex-end;
  transition: all 300ms linear;
`

const ButtonLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-items: flex-end;
  padding: ${props => props.theme.spacing.m};
  position: relative;
  left: 0;
  transition: all 300ms linear;
  width: 100%;
  background-color: #7C2F8B;

  &::before {
    background-color: #7C2F8B;
    border: 1px solid #7C2F8B;
    border-right-width: 0;
    bottom: -1px;
    content: '';
    display: block;
    position: absolute;
    left: -.8rem;
    top: -1px;
    transform: skew(-12deg);
    transform-origin: 0 100%;
    width: .8rem;
    z-index: -1;
    bottom: 0;
    top: 0;
    left: calc(-.8rem + 1px);
  }
`

const ButtonText = styled.span`
  transition: all 300ms linear;
`

const ButtonIcon = styled.span`
  background-color: #361647;
  border-color: #361647;
  position: relative;
  padding-right: .8rem;
  padding-left: .4rem;
  transition: all 300ms linear;
  width: 10px;
`

const ButtonArrow = styled.span`
  display: block;
  margin: 0 auto;
  top: 0;
  width: 10px;
  transition: all 300ms linear;

  &::before {
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
    content: '';
    display: block;
    height: .333em;
    position: absolute;
    right: 5px;
    transform: rotate(45deg);
    transform-origin: 100% 0%;
    top: 50%;
    width: .333em;
    transition: all 300ms linear;
  }

  &::after {
    content: '';
    border-top: 1px solid #fff;
    opacity: 1;
    position: absolute;
    left: 5px;
    right: 6px;
    top: 50%;
    transition: width 300ms linear;
    transform: translateY(-50%);
    width: calc(100% - 11px);
  }
`


Button.propTypes = {
  size: PropTypes.string,
};

Button.defaultProps = {
  size: 's',
};

export default function Button(props) {
  return (
    <StyledButton size={props.size} {...props} target={"_blank"}>
      <ButtonInner>
        <ButtonLabel>
            <ButtonText>{props.children}</ButtonText>
        </ButtonLabel>
        <ButtonIcon>
            <ButtonArrow></ButtonArrow>
        </ButtonIcon>
      </ButtonInner>
    </StyledButton>
  );
}
