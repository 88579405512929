import React from 'react';
import styled from 'styled-components';
import Button from './Button/Button';

const Container = styled.main`
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;

  #main {
  }
`;
//${props => props.theme.spacing.m};
const SkipLink = styled(Button)`
  position: absolute;
  pointer-events: none;
  opacity: 0;
  top: 0;
  left: 0;

  &:focus {
    opacity: 1;
  }
`;

const SkipLinks = () => (
  <div id="skiplinks">
    <SkipLink as="a" href="#navigation">
      Navigation
    </SkipLink>
    <SkipLink as="a" href="#main">
      Main content
    </SkipLink>
    <SkipLink as="a" href="#footer">
      Footer
    </SkipLink>
  </div>
);

export default function Layout(props) {
  const { children, template } = props;
  return (
    <Container template={template}>
      <SkipLinks />
      {children}
    </Container>
  );
}
