import React from 'react';
import styled from 'styled-components';

const ToggleWrapper = styled.a`
  position: fixed;
  z-index: 10;
  top: ${props => props.theme.spacing.s};
  right: ${props => props.theme.spacing.s};

  @media (min-width ${props => props.theme.breakpoints.l}){
    top: ${props => props.theme.spacing.m};
    right: ${props => props.theme.spacing.m};
  }
`;

const ToggleItem = styled.div`
  width: 45px;
  height: 42px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: inline-block;
  z-index: 10000;

  span {
    display: block;
    position: absolute;
    height: 5px;
    background: ${props => props.theme.colors.white};
    border-radius: 5px;
    opacity: 1;
    left: 5px;
    width: calc(100% - 10px);
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 5px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 18px;
    }

    &:nth-child(4) {
      top: 30px;
    }

    ${props =>
      props.active &&
      `
    &:nth-child(1) {
      top: 22px;
      width: 0%;
      left: 50%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 22px;
      width: 0%;
      left: 50%;
    }
	`}
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    display: none;
  }
`;

const Toggle = props => {
  return (
    <div>
      <ToggleWrapper onClick={props.onClickAction}>
        <ToggleItem active={props.toggleActive}>
          <span />
          <span />
          <span />
          <span />
        </ToggleItem>
      </ToggleWrapper>
    </div>
  );
};

export default Toggle;
